/**
 * POST data to the back end - a resonse code of 401 will redirect to the login page
 * @param {string} url
 * @param {Object} data
 * @returns {Promise} data
 * @throws {Error} if the response is not = 200 | 401 (unauthorised)
 */
async function postFetch (url, data, returnProperty = 'data') {
  // POST a fetch request to remove the instruction
  const response = await fetch(url, {
    'method': 'POST', // or 'PUT'
    'credentials': 'same-origin',
    'headers': {
        'Content-Type': 'application/json'
    },
    'body': JSON.stringify(data)
  });

  const parsed = await response.json();

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/logout.php';
    } else {
      if (Object.prototype.hasOwnProperty.call(parsed, returnProperty)) {
        throw new Error(parsed[returnProperty].exception);
      }
      throw new Error(`Response code: ${response.status}`);
    }
  }

  // all our response have a common format
  if (!Object.prototype.hasOwnProperty.call(parsed, 'count') || !Object.prototype.hasOwnProperty.call(parsed, returnProperty)) {
    throw new Error('Badly formed response');
  }

  return parsed[returnProperty];
}

/**
 *
 * @param {string} url
 * @param {FormData} formdata
 * @param {string} returnProperty
 * @returns
 */
async function postFetchFormData (url, formdata, returnProperty = 'data') {
  // POST a fetch request to remove the instruction
  const response = await fetch(url, {
    'body': formdata,
    'method': 'POST', // or 'PUT'
    'credentials': 'same-origin'
  });

  const parsed = await response.json();

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/logout.php';
    } else {
      if (Object.prototype.hasOwnProperty.call(parsed, returnProperty)) {
        throw new Error(parsed[returnProperty].exception);
      }
      throw new Error(`Response code: ${response.status}`);
    }
  }

  // all our response have a common format
  if (!Object.prototype.hasOwnProperty.call(parsed, 'count') || !Object.prototype.hasOwnProperty.call(parsed, returnProperty)) {
    throw new Error('Badly formed response');
  }

  return parsed[returnProperty];
}

/**
 * errors will need to be caught by the caller
 * @param {string} url
 * @param {URLSearchParams} params
 * @returns {Promise} data
 */
async function getFetch (url, params, returnProperty = 'data') {
  params.set('returnProperty', returnProperty);
  const response = await fetch(url + '?' + params, {
    'method': 'GET',
    'credentials': 'same-origin',
    'headers': {
      'Content-Type': 'application/json'
    }
  });

  const parsed = await response.json();

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/logout.php';
    } else {
      throw new Error(`${parsed} - Response code: ${response.status}`);
    }
  }

  // all our response have a common format
  if (!Object.prototype.hasOwnProperty.call(parsed, 'count') || !Object.prototype.hasOwnProperty.call(parsed, returnProperty)) {
    throw new Error('Badly formed response');
  }

  return parsed[returnProperty];
}

export { postFetch, getFetch, postFetchFormData };
