/**
 *   functionality for the login.php view
 */

import $ from 'jquery';
import toastBS5 from '../shared/alertsBS5';
import { postFetch } from '../shared/fetch';

$(function () {
  // the login form is hidden by default with a check to see if JavaScript is enabled
  $('#loginCard').removeClass('d-none');

  // show modal when user clicks on Forgotten Password
  $('body').on('click', '#loginFormResetPasswordButton', function (e) {
    e.preventDefault();
    console.log('Open forgotten password modal to get email address');
    $('div#resetUserPasswordModal').modal('show');
  });

  // forgotten password protocol -- get the email address and generate an email
  $('body').on('submit', '#resetUserPasswordForm', function (e) {
    e.preventDefault();
    console.log('submit resetUserPasswordForm');
    const email = $('input[name="resetemail"]').val();
    const parameters = {};

    if (email && email.length > 0) {
      parameters.email = email;
      parameters.r = 'forgottenPassword';

      postFetch('ajax/fetch_public.php', parameters, 'data')
        .then((response) => {
          console.log(response);
          toastBS5('Password reset email sent', 'success');
          $('#resetUserPasswordModal').modal('hide');
        })
        .catch((err) => {
          console.log(err);
          toastBS5(err.message, 'error');
        });
    }
  });


  /**
   * deal with request for a Magic Link
   */
  $('#magicLinkForm').on('submit', function (e) {
    e.preventDefault();
    console.log('Magic link request');

    const $form = $('#magicLinkForm');
    const email = $form.find('input[name="email"]').val();

    const data = { 'r': 'sendMagicLink', 'email': email };

    fetch('ajax/fetch_public.php', {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) {
          let r = '';
          switch (response.status) {
            case 400:
              toastBS5('Email address of active user is required', 'error');
              r = 'Bad request';
              break;
            default:
              r = `response status was ${response.status}`;
          }
          throw new Error(r);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
        toastBS5('Magic link sent - please check your email', 'success');

        $form[0].reset();
      })
      .catch((error) => {
        console.error(error);
      });
  });

  // if value of this hidden input field is "truthy" we open the Acknowledge modal when the page loads
  const emailResponse = $('input[name="emailResponse"]').val();
  console.log('emailResponse:', emailResponse);
  const ackEsrModalId = 'ackEsrModal';

  const ackEsrModal = document.getElementById(ackEsrModalId);
  if (!ackEsrModal) {
    console.error('Modal not found - check "id" attribute is ' . ackEsrModalId);
    return;
  }
  if (emailResponse) {
    $(`#${ackEsrModalId}`).modal('show');
  }
});
