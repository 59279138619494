import * as bootstrap from 'bootstrap';

/**
 * notifications using BS5 toasts - expects a container #popup
 * @param {string} message
 * @param {string} category - info* | warning | error | success
 * @param {number} duration - seconds before close (default = 5s)
 */
export default function toastBS5 (message, category = 'info', duration = 5) {
  // console.log(message);
  const body = document.querySelector('body');
  let container = document.getElementById('popup');

  // create a container 'div' element in the required position if not already exist
  if (!container) {
    container = document.createElement('div');
    container.id = 'popup';
    container.classList.add('toast-container', 'p-3');
    body.appendChild(container);
  }

  const toastDiv = document.createElement('div');
  toastDiv.classList.add('toast');

  // change colour based on the category parameter
  let bgColor = 'bg-info bg-opacity-75 text-white';
  let fill = '#007bff';
  switch (category) {
    case 'error':
      bgColor = 'bg-danger bg-opacity-75 text-white';
      fill = '#dc3545';
      break;
    case 'warning':
      bgColor = 'bg-warning bg-opacity-75 text-white';
      fill = '#ffc107';
      break;
    case 'success':
      bgColor = 'bg-success bg-opacity-75 text-white';
      fill = '#198754';
      break;
    default:
  }

  const toastHeadDiv = document.createElement('div');
  const title = category.charAt(0).toUpperCase() + category.substring(1);
  toastHeadDiv.innerHTML = `
  <div class="toast-header border-0 ${bgColor}">
    <svg class="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
      <rect width="100%" height="100%" fill="${fill}"></rect>
    </svg>
    <strong class="me-auto">${title}</strong>&nbsp;
    <small class="time">Just now</small>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>`;

  const toastBodyDiv = document.createElement('div');
  toastBodyDiv.classList.add('toast-body');
  toastBodyDiv.innerHTML = message;

  toastDiv.appendChild(toastHeadDiv);
  toastDiv.appendChild(toastBodyDiv);
  container.appendChild(toastDiv);

  const toast = new bootstrap.Toast(toastDiv, { 'delay': duration * 1000 });
  toast.show();

  // implement a count down
  let secondsAgo = 0;
  const timer = setInterval(function () {
    secondsAgo += 1;
    const timeDiv = toastDiv.querySelector('div.toast-header .time');
    if (timeDiv) {
      timeDiv.innerHTML = secondsAgo + 's ago';
    }

    if (secondsAgo > duration) {
      toast.hide();
      toastDiv.parentNode.removeChild(toastDiv);
      clearInterval(timer);
    }
  }, 1000);
}
